@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .glass {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);


  }
}

.rbc-month-view {
  height: 100vh;
}

.rbc-calendar {
  min-height: 100vh;
}



@media screen and (max-width: 992px) {
  .rbc-event-content {
    font-size: 0.8rem;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .rbc-event-content {
    font-size: 0.5rem;
  }
}

